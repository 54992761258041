import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { graphql, Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { kebabCase } from "lodash";
import Layout from "../../../components/layout";
import MainContent from "../../../components/maincontent";
import PageBanner from "../../../components/banner/banner";
import React from "react";
import Seo from "../../../components/seo";

const TagsPage = ({
  data: {
    allMarkdownRemark: { group }
  }
}) => (
  <Layout>
    <Seo
      title="Topics"
      description="Categories"
      lang="en"
      meta={[
        {
          content: "God, Jesus, Clear, Gospel, Truth, Heaven, Salvation, Saved, Grace, Free, How, Mid-Acts, Romans",
          name: "keywords"
        }
      ]}
    />

    <PageBanner
      hasImage={false}
      hasOverlay={false}
    />

    <MainContent
      hasArticle={true}
    >
      <div className='article-header'>
        <Link className="button button--grey" to="/blog" style={{ marginRight: "0.5rem" }}><FontAwesomeIcon icon={faChevronLeft} /> Blog </Link>
        <h1>All Topics</h1>
      </div>

      <div className="card">
        <div className="grid article-width">
          <ul className="blog-list--post-tags">
            {group.map((tag) => (
              <li key={tag.fieldValue}>
                <Link to={`/blog/tags/${kebabCase(tag.fieldValue)}/`}>
                  {tag.fieldValue} ({tag.totalCount})
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>

    </MainContent>
  </Layout>
);

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
